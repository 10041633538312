<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Bookings</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues-list-all' }"
                >
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              Bookings
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="eventsTableHeaders"
              :items="events"
              no-data-text="No Events have been booked"
            >
              <template v-slot:item.date="{ item }">
                {{ item.formatted_dates.date }}
              </template>
              <template v-slot:item.venue="{ item }">
                {{ item.venue.name }}
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-craigtoun-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  v-if="item.customer_id"
                  >{{ item.customer.full_name }}</router-link
                >
                <div v-else>{{ item.customer_name }}</div>
              </template>
              <template v-slot:item.has_responded="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.has_responded"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="yellow lighten-4 yellow--text text--darken-3"
                      v-on="on"
                      @click="openRestore(item)"
                    >
                      <v-icon small>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>Restore</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Booking</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.booking.ide }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Venues",
          disabled: false,
          to: {
            name: "module-craigtoun-venues-list",
          },
          exact: true,
        },
        {
          text: "Venue",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      eventsTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Venue", value: "venue" },
        { text: "Customer", value: "customer" },
        { text: "Total Attendees", value: "total_attendees" },
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Craigtoun Responded?", value: "has_responded" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        booking: {},
      },
    };
  },

  computed: {
    events() {
      let bookings =
        this.$store.getters["craigtoun/venuesStore/bookingsArchived"];
      return bookings;
    },
  },

  methods: {
    openRestore(booking) {
      this.restoreDialog.booking = booking;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.booking = {};
    },

    saveRestore() {
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/restoreBooking", {
          appId: this.$route.params.id,
          bookingId: this.restoreDialog.booking.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
